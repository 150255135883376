<template>
  <datatable :columns="columns" :data="cancels">
    <template v-slot:no-results>
      {{ $t('no_data_available_on_table') }}
    </template>
  </datatable>
</template>

<script>
import { getByUserId } from "@/services/cancel-service";

export default {
  data: () => ({
    cancels: []
  }),
  async mounted() {
    this.$emit('setLoading', true);
    try {
      const { data } = await getByUserId(this.$route.params.userId);
      this.cancels = data;
    } catch (error) {
      console.error('DEBUG: Cancel mounted error', error);
    }
    this.$emit('setLoading', false);
  },
  methods: {
  },
  computed: {
    columns() {
      return [
        { label: this.$i18n.t('calloff_overview_sublist_theader_insertdate'), field: 'created_at' },
        { label: this.$i18n.t('calloff_overview_sublist_theader_reason'), field: 'reason_description' },
        { label: this.$i18n.t('calloff_overview_sublist_theader_schedule'), field: 'schedule' },
        { label: this.$i18n.t('calloff_overview_sublist_theader_job'), field: 'job' },
      ]
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  width: 100%;

  &::v-deep th {
    text-align: left !important;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    padding: 5px 5px 5px 10px;
    min-width: 100px;
    width: 33.33%;
  }

  &::v-deep tbody > tr {
    &:hover, &:nth-child(odd) {
      background-color: #f9f9f9;
    }

    td {
      vertical-align: top;
      font-size: 13px;
      padding: 5px 5px 5px 10px;

      &[colspan="4"] {
        text-align: center;
      }
    }
  }
}
</style>

