import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const get = () => {
  return axios.get(`${API_ENDPOINT()}/cancels`);
};

export const getByUserId = (userId) => {
  return axios.get(`${API_ENDPOINT()}/cancels/${userId}`);
};
